/*
 * @Description:
 * @Version: 2.0
 * @Autor: libo
 * @Date: 2021-04-14 18:58:57
 * @LastEditors: hs
 * @LastEditTime: 2021-12-14 09:49:08
 */
// 配置编译环境和线上环境之间的切换

let baseUrl = ''

const env = process.env
if (env.NODE_ENV === 'development') {
  baseUrl = `/api` // 开发环境地址
} else if (env.NODE_ENV === 'production') {
  baseUrl = 'https://blth.park.fj-yuchen.com/api' // 生产环境地址
  // baseUrl = 'https://test.fj-yuchen.com/api' // 生产环境地址
  // baseUrl = 'http://192.168.8.221:8081/api' // 222
  // baseUrl = '/mock'; //生产环境地址
} else if (env.NODE_ENV === 'test') {
  baseUrl = `` // 测试环境地址
}
export { baseUrl, env }
