/*
 * @Description:
 * @Version: 2.0
 * @Autor: libo
 * @Date: 2021-04-14 18:58:57
 * @LastEditors: hs
 * @LastEditTime: 2021-10-14 16:43:56
 */
export default {
  title: '美峰社区管理平台', // 默认网页标题
  tokenKey: 'Admin-Token', // 与服务端约定的 token 前缀
  refreshTokenKey: 'Admin-Refresh-Token', // 与服务端约定的 refreshToken 前缀
  parkKey: 'parkId',
  client_id: 'smart_park', // 与服务端约定的授权参数
  client_secret: 'smart_park' // 与服务端约定的授权参数
}
